/* global jQuery,window */
'use strict';
;(function($) {
  $(function() {
    var $container = $('body:not(.single):not(.page) #main');

    var magnificOptions = {
      delegate: '.item:not(.invisible)',
      type: 'image',
      mainClass: 'mfp-fade',

      //closeOnContentClick:true,

      gallery: {
        enabled: true,
        preload: [0,2],
        navigateByImgClick: true,
        arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',

        tPrev: 'Zurück (Pfeiltaste links)',
        tNext: 'Weiter (Pfeiltaste rechts)',
        tCounter: '<span class="mfp-counter">%curr% von %total%</span>'
      }
    };
    $('#main').magnificPopup(magnificOptions);


    var masonryOptions = {
      itemSelector : '.cat:not(.invisible)',
      resizable: true,
      sortBy: 'original-order'
    };
    $container.masonry(masonryOptions);


    // masonry navigation on front page
    $('.home nav li').click(function(e) {
      var catId = parseInt($(this).attr('class').replace(/.*cat-item-(.*?)/,'$1'));

      // hide elements not in the selected category
      var elements = $container.masonry('getItemElements');
      $(elements).each(function() {
        var item = $container.masonry('getItem', this);
        if (!$(this).hasClass('cat-' + catId)) {
          item.hide();
          $(this).addClass('invisible');
        }
      });

      // make elements available for masonry which belong to the selected category
      $container.find('.invisible.cat-'+catId).removeClass('invisible');

      // make elements visible in original state for front page
      var isFront = (catId === window.categoryHomeId);
      if (isFront) {
        $container.find('.double').removeClass('no-double');
      } else {
        $container.find('.double').addClass('no-double');
      }


      // update masonry with newly visible elements
      $container.masonry('reloadItems');

      // reveal new elements with animation
      elements = $container.masonry('getItemElements');
      $(elements).each(function() {
        var item = $container.masonry('getItem', this);
        if ($(this).hasClass('cat-' + catId)) {
          item.reveal();
        }
      });

      $container.masonry();


      // set current menu item
      $('nav li').removeClass('active current-cat');
      $('nav li.cat-item-'+catId).addClass('active');


      // update slogan
      $('.slogan').addClass('hidden');
      $('.slogan.cat-'+catId).removeClass('hidden');

      e.preventDefault();
      e.stopPropagation();
      return false;
    });

    $('body:not(.single) header:not(.invisible)').click(function(e) {
      document.location.href = $(this).andSelf().find('a').first().attr('href');
      e.stopPropagation();
    });
  });
})(jQuery);
